export default {
  title: {
    id: 'myprofile_infos_title',
    defaultMessage: 'My Info',
  },
  verifyEmail: {
    id: 'myprofile_infos_verify_email',
    defaultMessage: 'Verify email',
  },
  emailVerified: {
    id: 'myprofile_infos_email_verified',
    defaultMessage: 'Verified',
  },
  emailVerifiedToastTitle: {
    id: 'myprofile_infos_email_verified_toast_title',
    defaultMessage: 'Email verified',
  },
  emailVerifiedToastContent: {
    id: 'myprofile_infos_email_verified_toast_content',
    defaultMessage: "You're all set!",
  },
  emailUnverified: {
    id: 'myprofile_infos_email_unverified',
    defaultMessage: 'Unverified',
  },
  updatePassword: {
    id: 'myprofile_infos_modify_password',
    defaultMessage: 'Change my password',
  },
  deleteAccount: {
    id: 'myprofile_infos_delete_account',
    defaultMessage: 'Delete your account',
  },
}
