import { MarketCountryCode } from '@backmarket/http-api/src/api-models/MarketCountryCode'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'

interface buybackProfileData {
  showRibCard: boolean
}

const buybackProfileConfig: Partial<
  Record<MarketCountryCode, buybackProfileData>
> = {
  [MarketCountryCode.FR]: {
    showRibCard: true,
  },
  [MarketCountryCode.DE]: {
    showRibCard: true,
  },
  [MarketCountryCode.GB]: {
    showRibCard: true,
  },
  [MarketCountryCode.ES]: {
    showRibCard: true,
  },
  [MarketCountryCode.US]: {
    showRibCard: true,
  },
}

export function useBuybackProfileConfig() {
  const { market } = useMarketplace()

  return buybackProfileConfig[market.countryCode] || null
}
