import { createHttpEndpoint } from '../../utils'

import type { UserProfileData } from './types'

/**
 * Retrieve the status of client profile email verification
 */
export const getUserProfileData = createHttpEndpoint<UserProfileData>({
  method: 'GET',
  operationId: 'retrieveClientProfileEmailStatus',
  path: '/auth/public/v1/users',
})
