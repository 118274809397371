import { createHttpEndpoint } from '../../utils'

import type {
  PostEmailVerificationRequest,
  PostEmailVerificationResponse,
} from './types'

/**
 * Start and email verification flow within the authzn stack
 */
export const postEmailVerificationFlow = createHttpEndpoint<
  PostEmailVerificationResponse,
  PostEmailVerificationRequest
>({
  method: 'POST',
  operationId: 'startEmailVerificationFlow',
  path: '/auth/email-verification/start-flow',
})

/**
 * Submit an email verification form
 */
export const submitEmailVerificationCode = createHttpEndpoint({
  method: 'POST',
  path: '/auth/email-verification/complete-flow',
  operationId: 'submitEmailVerificationCode',
})

/**
 * Ask to have the email verification resent
 */
export const resendEmailVerificationEmail = createHttpEndpoint({
  method: 'POST',
  path: '/auth/email-verification/resend-code',
  operationId: 'resendEmailVerificationCode',
})
